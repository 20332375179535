import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"

import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'

// import frankensteinHeader from '../images/FrankensteinPromo-65.jpg'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    productionsWrapper: {
      paddingBottom: "1em",
      maxWidth: "85rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    showsWrapper: {
      paddingBottom: "1em",
      maxWidth: "60rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    button: {
      margin: theme.spacing(1),
    },
    jumbo: {
        height: "100vh"
      },
  }));

export default function Frankenstein() {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query {
      frankenstein: file(relativePath: { eq: "franky-header-2024.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  
    return (
      <Layout>
        <SEO title="Frankenstein" />
        {/* <div class="jumbotron-wrapper-home"> */}

        <BackgroundImage 
          Tag="section"
          className={classes.jumbo}
          fluid={data.frankenstein.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          >
            <div class="jumbo-inner-wrapper-frankenstein">
                <div>
                  <h1 class="jumbo-title-wine">
                      Frankenstein
                  </h1>
                  <h5 class="subtitle-wine">Two Unique Experiences</h5>
                  {/* <div class="wine-buttons">
                      <a title="Find out more about Them!" href="https://www.nationaltheatrescotland.com/production/them/" class="button button--medium blackButton--border">
                          Frankenstein's Ball
                          </a>
                      <a title="Find out more about Them!" href="https://www.nationaltheatrescotland.com/production/them/" class="button button--medium blackButton--border">
                          Frankenstein's Funeral
                          </a>
                </div> */}
                </div>
            </div>
        </BackgroundImage>


      {/* <Grid container spacing={3} className={classes.productionsWrapper}> */}
      <div className={classes.productionsWrapper}>
        <div>
            <div class="frankenstein-content-block">
              <h3 class="frankenstein-description">
              Two hundred years have passed since Mary Shelley first horrified readers with her classic tale of a madly ambitious scientist and his monstrous creation. In honor of this momentous anniversary, Found Stages celebrates both the novel and author who terrified generations through two unique theatrical experiences.
              </h3>
            </div>  
          </div>
            <div class="frankenstein-content-row pastwork-row">
                <div class="frankenstein-content-wrapper">

                <Link to='/frankensteinsfuneral' class="no-decoration black-text">

                    <div class="frankenstein-pic-block frankenstein-funeral-pic-1">
                    </div>
                    <div class="frankenstein-content-block text-block">
                        <h2 class="glowist forty-font">Frankenstein's Funeral</h2>
                            <h4 class="centered-text kill-margin"><strong>OCTOBER</strong></h4>

                        <p style={{marginTop: '1.45rem'}}>Frankenstein’s Funeral takes audiences on a physical journey, guided by Mary Shelley, through her novel and the Gothic campus of St. John’s Lutheran Church at Halloween.</p>
                        <div class="links-container">
                          <p class="button button--medium button--border reset-width">Find out More</p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div class="frankenstein-content-wrapper">

                <Link to='/frankensteinsball' class="no-decoration black-text">

                    <div class="frankenstein-pic-block frankenstein-funeral-pic-2">
                    </div>
                    
                    <div class="frankenstein-content-block text-block">
                        <h2 class="glowist forty-font">Frankenstein's Ball</h2>
                        <h4 class="centered-text kill-margin"><strong>NEW YEAR'S EVE</strong></h4>

                        <p style={{marginTop: '1.45rem'}} >Frankenstein’s Ball invites audiences into the eccentric and extravagant world of Mary Shelley’s contemporaries as she struggles to find the right ending to her novel on New Year’s Eve.</p>
                        <div class="links-container">
                          <p class="button button--medium button--border reset-width">Find out More</p>
                        </div>
                    </div>

                  </Link>
                </div>
            </div>
          </div>
      {/* </Grid> */}
    </Layout>
    )
}
